$ristWarning: "ristWarning";
.#{ristWarning} {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  // z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #242e5f;
  &-bgImg {
    width: 292px;
  }
  &-close {
    width: 14px;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 5;
  }
  &-conent {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 44px 31px 39px 40px;
  }
  &-ENtitle {
    font-size: 13px;

    letter-spacing: 1px;
  }
  &-CNtitle {
    font-weight: bold;
    font-size: 28px;
  }
  &-line {
    width: 33px;
    height: 4px;
    background: #242e5f;
    margin: 30px 0 24px;
  }
  &-dec {
    font-size: 14px;
    line-height: 22px;
    span {
      color: #de0073;
      font-size: 16px;
    }
  }
  &-phoneBtn {
    width: 120px;
    height: 44px;
    line-height: 44px;
    margin-top: 81px;
    background: #de0073;
    border-radius: 22px;
    text-align: center;
    color: #ffffff;
    display: block;
  }
}
