$process: "process";

.#{$process} {
  position: relative;
  z-index: 1;
  padding: 61px 24px 43px 32px;
  &-slider {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .slider-wrap {
      flex: 1;
      height: 14px;
      background: #ef5122;
      border-radius: 11px 0 0 11px;
      position: relative;
      display: flex;
      align-items: center;
      padding: 0 6px;
      &::after {
        content: "";
        width: calc(100% + 6px);
        height: 20px;
        border-radius: 20px 0 0 20px;
        position: absolute;
        left: -3px;
        background: rgba(239, 81, 34, 0.5);
        z-index: -1;
      }
      .slider-step {
        height: 6px;
        border-radius: 6px 0 0 6px;
        background: linear-gradient(90deg, rgba(255, 245, 235, 1) 0%, rgba(255, 230, 155, 1) 100%);
        .slider-popover {
          height: 30px;
          line-height: 30px;
          border-radius: 4px;
          color: #fff2da;
          font-size: 14px;
          text-align: center;
          position: absolute;
          top: -15px;
          transform: translate(0%, -100%);
        }
      }
    }
    .gift-wrap {
      position: relative;
      &::before {
        content: "";
        width: 68px;
        height: 68px;
        border-radius: 50%;
        position: absolute;
        top: -4px;
        left: -4px;
        // background: rgba(239, 81, 34, 0.5);
        background: rgba(239, 81, 34, 1);
        z-index: -1;
      }
      &::after {
        content: "";
        width: 71px;
        height: 71px;
        border-radius: 50%;
        position: absolute;
        top: -5px;
        left: -5px;
        background: rgba(239, 81, 34, 0.5);
        z-index: -1;
      }
      .gift {
        width: 60px;
      }
    }
  }
  &-dec {
    color: #fff2da;
    display: flex;
    justify-content: space-between;
    margin-top: 13px;
  }
  &-money {
    margin-right: 12px;
    font-size: 19px;
    font-family: PingFangSC-Medium, PingFang SC;
    span {
      font-size: 14px;
    }
  }
}
