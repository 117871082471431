$record: "record";
.#{record}-item {
    position: relative;
    // width: 343px;
    width: 100%;
    height: 116px;
    border-radius: 4px;
    background-color: #f7f8f9;
    margin-bottom: 12px;
    padding: 11px 24px 15px;
    box-sizing: border-box;
}
.record-order-num {
    font-size: 13px;
    color: #848bb0;
    padding-bottom: 12px;
    border-bottom: 1px dashed #e4e6f1;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
}
.record-order-icon {
    width: 16px;
    margin-right: 4px;
}
