$ruleExplain: "ruleExplain";
.#{ruleExplain} {
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .more {
      color: #999999;
      font-size: 12px;
      display: flex;
      align-items: center;
    }
    .icon {
      width: 16px;
      margin: 0 3px;
    }
  }
  .explain {
    color: #333333;
    transition: all 0.3s;
    overflow: hidden;
    position: relative;
    margin-bottom: 28px;
    &-QA {
      background: #ffffff;
      border-radius: 8px;
      padding: 25px 20px;
      line-height: 24px;
      transition: all 0.3s;
      .flex {
        display: flex;
      }
      .icon {
        width: 16px;
        height: 16px;
        margin-right: 12px;
        margin-top: 3px;
      }
      &-item {
        margin-bottom: 31px;
      }
      &-Q {
        font-size: 16px;
        display: flex;
        margin-bottom: 8px;
      }
      &-A {
        font-size: 13px;
        display: flex;
      }
    }
    &-tips {
      color: #999999;
      font-size: 13px;
    }
  }
}
