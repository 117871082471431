$App: "App";
.#{$App} {
  width: 100%;
  background-color: #f0f1f5;
  color: #000000;
  /* line-height: 200px; */
  /* text-align: center; */
  min-height: 100vh;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  &-logo {
    height: 80px;
  }
  img {
    content: normal !important;
    pointer-events: auto;
    // background: url('./assets/loading.gif') no-repeat center center;
    // background-color: #9297B0;
  }
  div::-webkit-scrollbar {
    width: 0 !important;
  }
  div {
    -ms-overflow-style: none;
  }
  div {
    overflow: -moz-scrollbars-none;
  }
  input {
    border: 0;
    border-style: none;
    appearance: none;
    width: 100%;
    background: transparent;
    // line-height: 1.5 !important;
  }
}
$themeColor1: #242e5f;
$themeColor2: #9297b0;
$themeColor3: #ffffff;
$themeColor4: #c8ccdc;
$themeColor5: #848bb0;
$themeColor6: #a6abc2;
$themeColor7: #de0073;

$themeFont25: 25px;
$themeFont14: 14px;
$themeFont12: 12px;
$themeFont13: 13px;
$themeFont16: 16px;
$themeFont18: 18px;
$themeFont20: 20px;
$themeFont32: 32px;
$themeFont22: 22px;

.act-btn {
  user-select: none;
  &:active {
    color: hsla(0, 0%, 100%, 0.3) !important;
    opacity: 0.5;
  }
}
.bounceIn {
  animation-name: bounceIn;
  animation-duration: 0.55s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
