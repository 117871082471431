.refresh-wrap {
    position: relative;
    touch-action: pan-y;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
}
.refresh-concent-wrap {
    min-height: 100%;
}
.list-body {
    height: 100%;
    overflow: hidden;
}
.loading {
    color: grey;
    text-align: center;
    height: 25px;
    line-height: 25px;
    margin-bottom: -25px;
    font-size: 14px;
}
.refresh-transition {
    transition: transform 0.25s;
}
