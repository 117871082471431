// eslint-disable-next-line
@import "@/App.scss";
$prepare: "prepare";

.#{$prepare} {
  position: relative;
  &-title {
    color: $themeColor1;
    font-size: $themeFont32;
    padding-top: 40px;
    padding-left: 42px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
  }
  &-line {
    width: 306px;
    padding-left: 42px;
    height: 1px;
  }
  &-tips {
    color: $themeColor5;
    font-size: $themeFont16;
    padding-top: 27px;
    padding-left: 42px;
  }
  &-input {
    margin-top: 14px;
    background-color: $themeColor3;
    width: 305px;
    height: 50px;
    margin-left: 42px;
    border-radius: 4px;
    display: flex;
    .input-bank {
      width: 40px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .input-img {
      width: 20px;
    }
    .input-wb {
      width: 265px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .input-ip {
        width: 229px;
        // height: 100% !important;
        font-size: $themeFont14;
        // height:20px;
        &::-webkit-input-placeholder {
          /* WebKit browsers */
          color: $themeColor6;
        }
        &::-moz-placeholder {
          /* Mozilla Firefox 4 to 18 */
          color: $themeColor6;
          opacity: 1;
        }
        &::-moz-placeholder {
          /* Mozilla Firefox 19+ */
          color: $themeColor6;
          opacity: 1;
        }
        &::-ms-input-placeholder {
          /* Internet Explorer 10+ */
          color: $themeColor6;
        }
      }
    }
  }
  &-btn {
    width: 314px;
    height: 46px;
    border-radius: 25px;
    background-color: $themeColor7;
    margin: 0 auto;
    margin-top: 250px;
    text-align: center;
    line-height: 46px;
    color: $themeColor3;
    position: relative;
    z-index: 2;
  }
  &-login {
    color: $themeColor7;
    margin: 0 auto;
    margin-top: 24px;
    width: 150px;
    position: relative;
    z-index: 2;
  }
  &-foot {
    font-size: $themeFont13;
    color: $themeColor2;
    width: 283px;
    margin: 0 auto;
    margin-top: 40px;
    position: relative;
    z-index: 2;
  }
  &-img {
    width: 216px;
    position: absolute;
    right: 0;
    top: 295px;
    z-index: 1;
  }
}
