$mission: "mission";
.#{mission} {
  &-item {
    width: 343px;
    border-radius: 8px;
    background: #ffffff;
    padding: 0 25px;
    box-sizing: border-box;
    margin: 12px 0;
    position: relative;
    // overflow: hidden;
    transition: height ease-out 0.25s;
    &-main {
      height: 70px;
      display: flex;
      align-items: center;
      position: relative;
    }
    &-icon {
      width: 26px;
      margin-right: 14px;
    }
    &-conent,
    &-money {
      display: inline-block;
    }
    &-conent {
      font-size: 14px;
    }
    &-money {
      flex: 1;
      text-align: right;
      color: #fa6400;
      font-size: 18px;
      span {
        font-size: 13px;
      }
    }
    &-isFinish {
      position: absolute;
      right: 0;
      top: 0;
      img {
        width: 40px;
      }
    }
    &-info {
      border-top: 1px dashed #eef0f7;
      font-size: 12px;
      display: flex;
      justify-content: space-between;
      height: 50px;
      .item {
        display: flex;
        align-items: center;
        &:last-child {
          color: #fb8c43;
        }
        &:first-child {
          color: #999999;
        }
      }
      .icon {
        width: 14px;
        margin-right: 6px;
      }
    }
  }
}
