$getBoundMask: "getBoundMask";

.#{getBoundMask} {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 10;

  .content {
    width: 100%;
    position: absolute;
    top: 224px;
    z-index: 10;
    text-align: center;
    .boundNum {
      color: #a88d39;
      position: absolute;
      top: 35%;
      left: 50%;
      transform: translateX(-60%);
      .num {
        font-size: 30px;
        font-weight: bold;
        span {
          font-size: 17px;
          font-weight: normal;
          margin-left: -5px;
        }
      }
      .text {
        font-size: 13px;
        margin-top: 2px;
      }
    }
  }
  .giftWrap {
    width: 330px;
    height: 252px;
    margin: 0 auto;
    position: relative;
  }
  .gift {
    width: 100%;
  }
  .mask {
    width: 189px;
    height: 46px;
    line-height: 46px;
    background: rgba(0, 0, 0, 0.57);
    border-radius: 23px;
    color: #fff;
    margin: 19px auto;
    span {
      color: #fbe93b;
    }
  }
}
