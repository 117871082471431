.class-cropper-modal {
  position: fixed;
  // background-color: rgba(#000000, 0.3);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .modal-panel {
    width: 100vw;
    height: 100vh;
    background-color: #9d9fa1;
    // padding: 20px;
    display: flex;
    align-items: center;
    .cropper-face{
      border-radius: 50%;
      background-color: #fff;
      opacity: 0;
    }
    .cropper-view-box{
      outline: none;
      border-radius: 50%;
    }
    .cropper-container-container {
      width: 100vw;

      .cropper-container {
        .cropper {
          width: 100%;
          height: 100%;
        }
      }
      .preview-container {
        flex: 1;
        display: flex;
        align-items: flex-end;
        .cropper-preview {
          width: 180px;
          height: 180px;
          overflow: hidden;
          border: 1px solid #383838;
        }
      }
    }
  }
  .foot{
    position: fixed;
    bottom: 0;
    width: 100vw;
    display: flex;
    align-items: center;
    height: 66px;
    background:rgba(0,0,0,1);
    opacity:0.3;
    justify-content: space-between;
    font-size:16px;
    font-family:PingFangSC-Medium,PingFang SC;
    font-weight:500;
    color:rgba(255,255,255,1);
    .foot-left{
      margin-left: 24px
    }
    .foot-right{
      margin-right: 24px;
    }
  }
}